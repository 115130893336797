	
	var menuToggle = $(".menu-toggle");

	// var isDesktop = true;
	var isMobile = $("#is-mobile").is(":visible");
	var isTablet = $("#is-tablet").is(":visible");
	var isDesktop = $("#is-desktop").is(":visible");
	
	var isIE = is.ie();
	var isEdge = is.edge();
 
	 
	if (!$(".video-banner.heading").length || is.desktop() || isIE || isEdge) {
		$(window).load(function(){ 
			//setTimeout(function(){ 
				$("body").addClass("loaded");
			//},40000);
		});
	};


	
	if (!is.desktop()) {
		var duration;
		if ($("body").hasClass("home")) {
			duration = 1000;
		}else{ 
			duration = 0; 
		}
		$(window).load(function(){
			setTimeout(function(){ 
				$("body").addClass("loaded");
			},duration);
		});
	};
 

		$("a[href^='http://" + top.location.host.toString()+"'], a[href^='/'],a[href^='https://" + top.location.host.toString()+"']").on('click',function(e) {
			var url = $(this).attr('href');
			$('body').addClass('is-changing-page');
			setTimeout(function() {
				location.href=url;
			},800);
			return false;
		});

 


	menuToggle.click(function(e){
		//$("body, html").toggleClass("menuOpen"); 
		fullMenuSwitch(e);
	});

	$("menu a").click(function(e){
		fullMenuSwitch(true, e);
	});


	// setInterval(function(){
	// 	menuToggle.trigger("click");
	// }, 5000);

	// Full menu interactions
		var currScr;

		function fullMenuSwitch(linkOut, e){

			if (linkOut === undefined) {
				linkOut = false;
			};

			if (linkOut == true) {

				$("menu .loaded").removeClass("loaded"); 
					setTimeout(function(){ 
						var href = e.currentTarget.href; 
						window.location.href = href; 
					}, 800);  
				e.preventDefault();

			} else{ 

				// Close
				if ($("body").hasClass('menuOpen')) {   
					$("menu .loaded").removeClass("loaded"); 
					$("body").removeClass("menuOpen");  
				 	$('html, body').animate({scrollTop:currScr},0); 
				}  // if

				// Open
				else{ 

					currScr = currentScroll;

					$("body").toggleClass("menuOpen");
					$(".mobile-actions [data-toggle='menu']").toggleClass("icon-menu-r").toggleClass("icon-cross-r");
					$("menu .loaded").removeClass("loaded").addClass("no-anim"); 
					setTimeout(function(){
						scrollContent();
					}, 600);  
				 
				} // else

			} // if else

		} // fullMenuSwitch

		// $(".mobile-actions [data-toggle='menu']").click(function(){
		
		// });
  

		$("a").click(function(e){
			var link = $(this).attr("href");
			if ($(".full-menu").is(":visible")) {
				e.preventDefault();
				$(".full-menu .loaded").removeClass("loaded");
				setTimeout(function(){
					//$("menu").fadeOut(400, function(){
						window.location.href = link;
					//});
				},400);
			};
		});




	//  -------------------------------------------------
	//  TILES Utility  

		$(".match-height").each(function(){
			// Get highest parent from element (of type .row)
			var highestParent = $(this).parents(".row").last(); 
			// Get height of reference parent
			var refHeight = highestParent.height();
			// Give determined height to element
			$(this).height(refHeight);
		});

	//  -------------------------------------------------
	//  MAP - Using provided address, or coords 
	

	// Locate map center point using provided address


	function locate_using_coords(getLat, getLng, map){ 
	 	// If lat lng are provided
	 	var lat_lng = { lat: getLat, lng: getLng};	
	 	// Recenter map using coords
	 	map.setCenter(lat_lng);
	 	// Place a marker using coords
	    var marker = new google.maps.Marker({
	        map: map,
	        position: lat_lng
	    });
	} // locate_using_coords()


	function locate_using_address(address,resultsMap,dataLat,dataLng,currentMapWrap, markerBool) {

		var geocoder = new google.maps.Geocoder();
	  	geocoder.geocode({'address': address}, function(results, status) {
	    
	    // If geocoding successfull
	    if (status === google.maps.GeocoderStatus.OK) {
	      resultsMap.setCenter(results[0].geometry.location);
	      if (markerBool == 1) {
	      	 var marker = new google.maps.Marker({
		        map: resultsMap,
		        position: results[0].geometry.location
		      });
		   };
	     
	    } // geocoding successful

	    // If geocoding unsuccessful from provided address 
	    // (ex: no address provided), Use provided lat-lng instead.
	    else { 
	      	console.log(currentMapWrap.attr("class")+" -> Geocoding unsuccessful ("+address+"); " + status); 
	      	//currentMapWrap.hide();
	   		if(dataLat != "" && dataLng != ""){
		 		locate_using_coords(dataLat, dataLng, resultsMap); 
		 	} 
		 	else{
		 		currentMapWrap.hide();
		 	}
	    } // If geocoding unsuccessful

	  }); 

	} // locate_using_address

	function initMap() {

		// Witness pointer
		var index = 0; 

		var colors = [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}];
	

		// If at least one map on page

		if ($(".js-map").length) { 

			// Browse each map info and create a map
		 	$(".js-map").each(function(){

		 		// Parameters

		 		var zoom = $(this).data("zoom"); 
		 		if (zoom == undefined || zoom == "") { zoom = 10; };

		 		var colored = $(this).data("colored"); 
		 		if (colored == undefined || colored == "" || colored == 0) { colored = 0; } 
		 		else{ colored = colors;};

		 		var marker = $(this).data("marker"); 
		 		if (marker == undefined || marker == "") { marker = 0; };

		 		var ui = $(this).data("ui"); 
		 		if (ui == undefined || ui == "" || ui == 0) { ui = true; }
		 		else{ ui = false; }


		 		// Map declaration

		 		var map = new google.maps.Map($(".js-map")[index], {
				    zoom: zoom,
				    center: {lat: 0, lng: 0},
				    scrollwheel: false, 
				    styles : colored,
				    disableDefaultUI: ui
				});

		 		// If address provided :  

		  		var address = $(this).data("addr"); 
		  		var dataLat = "";
			 	var dataLng = "";

			 	if ($(this).data("lat") != "" && $(this).data("lng")) {
			 		dataLat = $(this).data("lat");
			 		dataLng = $(this).data("lng");
			 	}

			 	// // Initiate geocoding from map's data address, if provided
			 	if (address != "") {
			 		locate_using_address(address, map, dataLat, dataLng, $(this), marker); 
			 	} 
			 	// If not, use coordinates
			 	else if(dataLat != "" && dataLng != ""){
			 		locate_using_coords(dataLat, dataLng, map); 
			 	} else{
			 		console.log($(this).attr('class')+" -> "+$(this).index()+" -> No data involved.");
			 		$(this).hide();
			 	}
			 	
			 	index++;

		 	});

	 	} // If js-map contains something
 		

 	} // initMap

 	//initMap();

	
	//  END MAP - Using provided address, or coords 
	//  -------------------------------------------------

	//  -------------------------------------------------
	//  ACCORDIONs - Slide down - up

		var accordion = $(".accordion");
		var accordion_length = accordion.length;

		if (accordion_length > 0) {

			var elem = $(".element");
			var title = $(".title");
			var content = $(".content");
			
			elem.click(function(){
				$(this).toggleClass("active");
				$(this).find(content).slideToggle(200);
			});

		};

	//  -------------------------------------------------
	//  TOGGLE Utility

	$("[data-toggle]").click(function(){
		var target = $(this).data("toggle");
		$("[data-elem='"+target+"']").slideToggle();
	});
	
	$("menu nav .mobile-actions [data-toggle='menu']").click(function(){
		$(this).parents("menu").toggleClass("active");
	});

	//  -------------------------------------------------
	//  SCROLL Utility

	$("button[data-scroll='scrollTarget']").click(function(){
		var target = $(this).data("scroll");
		var targetOffset = $("[rel='"+target+"']").offset().top - ($("header").innerHeight());
		//alert(targetOffset);
	//	alert();
		setTimeout(function(){
			lity($("[rel='"+target+"']").find(".playVideo").data("mob-url")); 
		}, 1000);
		$('html, body').animate({scrollTop: targetOffset}, 1000, function(){
			
		});
		

	});
	
	   
	//  -------------------------------------------------
	//  TABS Utility


	$('.nav-tabs a').click(function (e) {
	  e.preventDefault();
	  $(this).tab('show');
	});


	$(window).load(function(){
	/* ////////////////////////////////////////
	//
	// Init
	//
	/////////////////////////////////////// */

	//du code soit pour le loading mask ou pour initialiser le loading du site

		}).scroll(function(){
			scrollContent(); 
			// Un alert ici fonctionne au scroll
		});

		/* ////////////////////////////////////////
			//
			// Scroll content
			//
			/////////////////////////////////////// */
		function scrollContent(){
			
			// Un alert ici ne fonctionne pas au scroll

			var totalScroll = $(document).height() - $(window).height();

			//if(browserMobile){
			newScroll = $(window).scrollTop();
			// } else {
			// 	if(whichBrs() == 'Safari' || whichBrs() == 'Chrome'){
			// 		newScroll = $('body').scrollTop();
			// 	} else {
			// 		newScroll = $('html,body').scrollTop();
			// 	}
			// }

			currentScroll = newScroll;

			/* To-load
			================================================== */
			$('.to-load').each(function(){
				var object = $(this);		
					
				if(newScroll + $(window).height() * 0.85 > $(this).offset().top){
					object.removeClass('no-anim');
					object.addClass('loaded');
				} else if(newScroll + $(window).height() < $(this).offset().top) {
					object.addClass('no-anim');
					object.removeClass('loaded');
				}
					
			});
			
		}

		setTimeout(function(){
			scrollContent();
		}, 1000);

		

			$('#background > div > img').each(function(){
				var posLeft = ($(this).width() - $(this).parent().width()) / 2;
				$(this).css({'left': - posLeft});
			});

			scrollContent();





	//  -------------------------------------------------
	//  YOUTUBE API 
	
	  		// BANNER VIDEO - Youtube API
 

		// Proportion test for the video wrap

		var vidwrap = $(".video-banner");   

		// IF IS TABLET OR DESKTOP

		// if (isDesktop) {

		// 	alert("mobile");
		// 	// $(".playVideo").each(function(){
		// 	// 	if ($(this).attr("data-mob-url") != "") {
		// 	// 		//$(this).show();
		// 	// 	}; 
		// 	// });
		// 	$(".playVideo").click(function(){
		// 		window.open(
		// 		  $(this).data('mob-url'),
		// 		  '_blank' // <- This is what makes it open in a new window.
		// 		);
		// 	});
		// }
		// else{
			$(".playVideo").click(function(){
                var lightbox = lity($(this).data("mob-url")); 
                //var lightbox = lity("https://vimeo.com/93003441"); 
            });
		// }


		if (is.desktop()) { 

        // GET VID ID

			function getYoutubeID(url){
			  var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
			  var match = url.match(regExp);
			  if (match && match[2].length == 11) {
			    return match[2];
			  } else {
			    //error
			  }
			}  

          	var player;  
 
          	//  -------------------------------------------------
          	//  VIDEO YOUTUBE API IMPORT 
         
	          if (!window['YT']) {
	              var YT = { loading: 0, loaded: 0 }; }
	          if (!window['YTConfig']) {
	              var YTConfig = { 'host': 'https://www.youtube.com' }; }
	          if (!YT.loading) { YT.loading = 1;
	              (function() {
	                  var l = [];
	                  YT.ready = function(f) {
	                      if (YT.loaded) { f(); } else { l.push(f); } };
	                  window.onYTReady = function() { YT.loaded = 1;
	                      for (var i = 0; i < l.length; i++) {
	                          try { l[i](); } catch (e) {} } };
	                  YT.setConfig = function(c) {
	                      for (var k in c) {
	                          if (c.hasOwnProperty(k)) { YTConfig[k] = c[k]; } } };
	                  var a = document.createElement('script');
	                  a.type = 'text/javascript';
	                  a.id = 'www-widgetapi-script';
	                  a.src = 'https:' + '//s.ytimg.com/yts/jsbin/www-widgetapi-vflO1YZr2/www-widgetapi.js';
	                  a.async = true;
	                  var b = document.getElementsByTagName('script')[0];
	                  b.parentNode.insertBefore(a, b); })(); }

          	//  END VIDEO YOUTUBE API IMPORT 
          	//  -------------------------------------------------
 
 		 
	        //  -------------------------------------------------
	        //  Youtube API Ready 
	        
	          function onYouTubeIframeAPIReady() {

	        	console.log("Video Api ready.");

	            if ($(".video-container").length) { 

	            	YTobj = {};

		          	YTobjShort = [];
		          	YTobjLong = []; 

		          	var objPointer = 0;
		          	//console.log(players);

	          		$(".video-container").each(function(){

	          			var vidObj = {};

	          			var indexOf = $(this).index(".video-container");
	          			console.log("-------This is index : "+indexOf);

	          			console.log("/### START Video container/");

	          			$(this).find(".video-wrap").each(function(){ 

	          				console.log("/### START Video wrap/");
		          			  
	          				var wrap = $(this);

	          				if ($(this).hasClass("short")) { 

	          						console.log("There is a short video in this video-container");

	          						var targetShort = $(this).parents(".video-container").data("vid-short");
	          						console.log("TargetShort " + targetShort);

	         		 				var video = {
				          			//type : vidType,
				          			videoObj : new YT.Player($(this).find(".player").get(0), {

										videoId: getYoutubeID(targetShort),

										playerVars: {
										  'autoplay': 0,
										  'controls': 0,
										  'showinfo': 0,
										  'rel': 0
										},

										events: {
										  'onReady': function(e){

										  	videoInit(indexOf);
										  	setTimeout(function(){ 
												$("body").addClass("loaded");
											},1000);
										
										  	//console.log(YTobjShort);  
										  	// console.log("------");
										  	// console.log($(this)); 
										  	// e.target.mute().playVideo();
										  	// setTimeout(function(){
										  	// 	wrap.removeClass("off").addClass("on");
										  	// },500);
										  	// console.log("@@@@ Test");
										  	// console.log(e);  
										  },
										  'onStateChange': function(e){
										  	var player = e.target; 
								            // Possible behaviours per state
								            switch (e.data) {

								                // Video ended
								                case 0: 
								                		// console.log();
								                   		//vidwrap.removeClass("theater");
									            		player.seekTo(0, true); 
									            		player.mute();  
									            		player.playVideo(); 
									            		//player.pauseVideo(); 
								                break; 
								                
								                // Video started
								                case 1: 
								                break;

								            } // switch event.data
										  },
										},

						            })
				          		}; // video

				          		vidObj.short = video;

				          		// Save in array

	          				}else if( $(this).hasClass("long") ){

	          						console.log("There is a long video in this video-container");

	          						var targetLong = $(this).parents(".video-container").data("vid-long");
	          						console.log("targetLong " + targetLong);

		          					var video = { 
					          			videoObj : new YT.Player($(this).find(".player").get(0), {

											videoId: getYoutubeID(targetLong), 
											playerVars: {
												// 'height': 2000,
												// 'width':3000,
											  'autoplay': 1,
											  'controls': 1,
											  'showinfo': 0,
											  'hd' : 1,
											  'hq' : 'hd1080',
											  'rel': 0
											},

											events: {
											  'onReady': function(e){

											  	videoInit(indexOf);

											  
											  	// e.target.mute().playVideo();
											  	// setTimeout(function(){
											  	// 	e.target.pauseVideo();
											  	// }, 1000);



											  },
											  'onStateChange': function(e){
											  	var player = e.target; 
									            // Possible behaviours per state
									            switch (e.data) {

									                // Video ended
									                case 0: 


									                		
									                		console.log("---/---/---/---/---/---/---/---");
									                		console.log(player.parents());
									                		//console.log(objPointer);
									                		//$(this).parents(".full-screen").removeClass("full-screen");
									                		
								       //    					$(".actions:eq("+$("")+")").addClass("playing").removeClass("notPlaying").addClass("off");
															// $(".video-container:eq("+parentIndex+")").find(".long").addClass("off");
															// longVid.seekTo(0, true).pauseVideo();  

															// if ($(".global-video:eq("+parentIndex+")").find(".video-wrap.short").length) {
															// 	$(".video-container:eq("+currIndex+")").find(".short").removeClass("off");
															// 	var shortVid = YTobj[parentIndex].short.videoObj;
															// 	shortVid.playVideo();  
															// 	$(".global-video:eq("+parentIndex+")").find(".playVideo").show();
															// }else{
															// 	//alert("There is NO short video.");
															// 	$(".global-video:eq("+parentIndex+")").find(".playVideo").show();
															// }

									                break; 
									                
									                // Video started
									                case 1: 
									                break;

									            } // switch event.data
											  },
											},

							            })
					          		}; // video

					          		// Save in array 
					          		vidObj.long = video;


	          				}   
	          				
	          				console.log("/### END Video wrap/");
	          				
		            	}); // each wrap 

		            	YTobj[objPointer] = vidObj;

		            	  	// videoInit(0);
		            	//videoInit(1);
	 	
          				objPointer++;

          				console.log("/### END Video container/");

          				console.log('Video added to global YTobj');

	          		}); // each video container


					// Check every video container to determine the init process
		            // If there's only one short video : fire
		            // If there's a short, and a long : fire and show play button
		            // If there's a long only : show play button only
			        function videoInit(containerIndex){

			        	console.log(YTobj);

			        	//console.log(YTobj);
			        	
			        	//console.log(containerIndex);


			        	
			        	// Get video container with required index
			        	var thisContainer = $(".video-container:eq("+containerIndex+")");

			        	// Get play video button 
			        	var playButton = $(".video-container:eq("+containerIndex+") .playVideo, .video-container:eq("+containerIndex+") ~ .container .playVideo");
			        	// Play actions
			        	var playActions = $(".video-container:eq("+containerIndex+") .actions, .video-container:eq("+containerIndex+") ~ .actions");

			      //   	playButton.click(function(){ 


			      //   		if (!thisContainer.parents(".video-banner").hasClass("banner")) {
			      //   			var containerWidth = $(this).parents(".container").width();
			      //   			var windowH = $(window).height();
			      //   			var containerHeight = containerWidth * (9/16);
			      //   			var offset = windowH - containerHeight;
			      //   			offset = offset / 2;
			      //   			var thisContainerOffsetTop = $(this).parents(".video-banner").offset().top; 
			        		
			      //   			$('html, body').animate({scrollTop: thisContainerOffsetTop - offset}, 600);

			      //   			//alert(windowH + "  " + containerHeight);
			      //   			 $(this).parents(".video-banner").width(containerWidth);

		       //  				console.log();
		       //  			} 

			      //   		if (thisContainer.find(".video-wrap.short").length) {

			      //   			YTobj[containerIndex].short.videoObj.pauseVideo();

			      //   			thisContainer.parents(".video-banner").addClass("full-screen"); 
			      //   			//alert();
			        			
			      //   			//$(".fixed-placeholder").show();
			      //   			$("header").hide();

			      //   			setTimeout(function(){

			      //   				thisContainer.find(".video-wrap.short").addClass("off"); 

			      //   				playButton.hide();
			      //   				playActions.removeClass("off");

									// YTobj[containerIndex].long.videoObj.playVideo();
								 
			      //   				setTimeout(function(){
			      //   					thisContainer.find(".video-wrap.long").removeClass("off");
			      //   					//.toggleClass("theater"); 
			      //   					//alert(thisContainer.parents(".video-banner").length);
			      //   					playActions.removeClass("off");
			      //   				}, 400);

			      //   			}, 400);

			      //   		}
			      //   		// On click of the playbutton, if there is no short video playing...
			      //   		if (thisContainer.find(".video-wrap.long").length && !thisContainer.find(".video-wrap.short").length) {
			      //   			//YTobj[containerIndex].short.videoObj.pauseVideo();
			      //   			//	alert();
			      //   			setTimeout(function(){ 

			      //   				playButton.hide();
			      //   				playActions.removeClass("off");

									// YTobj[containerIndex].long.videoObj.playVideo();
									// thisContainer.parents(".video-banner").addClass("full-screen"); 
									// $("header").hide();
									// //$(".fixed-placeholder").show();
			      //   				setTimeout(function(){
			      //   					thisContainer.find(".video-wrap.long").removeClass("off"); 
			      //   					playActions.removeClass("off");
			      //   				}, 400);

			      //   			}, 400);
			      //   		};

			      //   	}); // playButton Click

			        	console.log("-------"+thisContainer.length);

			        	// Determine which videos this container has, and deduce a procedure
			        	if (thisContainer.find(".video-wrap.short").length) {
			        		// Fire short video

			        			// start video and show it after one second
			        			
			        			YTobj[containerIndex].short.videoObj.seekTo(1, true).pauseVideo();

			        			setTimeout(function(){
			        				YTobj[containerIndex].short.videoObj.playVideo();
			        				var player = YTobj[containerIndex].short.videoObj;
			        				player.seekTo(0, true); 
				            		player.mute();  
				            		player.playVideo(); 
				            		//alert();
			        				thisContainer.find(".video-wrap.short").removeClass("off");
			        				if (thisContainer.find(".video-wrap.long").length) {
			        					//console.log("&?&&&&&&&&&&&&");
			        					YTobj[containerIndex].long.videoObj.seekTo(1, true).pauseVideo();
			        				//	playButton.show();
			        				};
			        			},1000);
 
			        			// show play button

			        			//console.lo

			        	};
			        	// If there's only one long video, no short
			        	if (thisContainer.find(".video-wrap.long").length && thisContainer.find(".video-wrap.short").length < 1) {
			        		  
			        		YTobj[containerIndex].long.videoObj.seekTo(1, true).pauseVideo();

		        			setTimeout(function(){ 
		        			//	playButton.show();
		        			},1000); 

			        	};

			        } // videoInit 

	            }; // if video-container  

	        } // onYouTubeIframeAPIReady  

	        $(".close-button").click(function(){
	        	$(this).parents(".full-screen").removeClass("full-screen");
	        });


			$("body").on('click', '.framed .actions span',function(e){ 
			//	alert();
				var buttonState = $(this).attr("class");
				//alert(buttonState);
				var currIndex = $(e.currentTarget).parents(".actions").index(".actions");
				 
				var parentIndex = $(e.currentTarget).parents(".global-video").index(".global-video");
				//	alert(currIndex);
				var longVid = YTobj[parentIndex].long.videoObj;
				if (buttonState == "vidAction_pause") {
					longVid.pauseVideo();  
					$(e.currentTarget).parents(".actions").toggleClass("playing").toggleClass("notPlaying");
				};
				if (buttonState == "vidAction_play") {
					longVid.playVideo();  
					$(e.currentTarget).parents(".actions").toggleClass("playing").toggleClass("notPlaying");
				};

				//  -------------------------------------------------
				//  STOP 
				 
				if (buttonState == "vidAction_stop") { 


					$(e.currentTarget).parents(".video-banner").removeClass("full-screen").width("100%"); 
					//$(".fixed-placeholder").hide();
				$("header").show();
					// Toggle class -> remettre en fine tuning
					//$(".video-container:eq("+currIndex+")").parents(".video-banner").toggleClass("theater"); 
					
					$(e.currentTarget).parents(".actions").addClass("playing").removeClass("notPlaying").addClass("off");
					$(".video-container:eq("+parentIndex+")").find(".long").addClass("off");
					longVid.seekTo(0, true).pauseVideo();  

					if ($(".global-video:eq("+parentIndex+")").find(".video-wrap.short").length) {
						$(".video-container:eq("+currIndex+")").find(".short").removeClass("off");
						var shortVid = YTobj[parentIndex].short.videoObj;
						shortVid.playVideo();  
						//$(".global-video:eq("+parentIndex+")").find(".playVideo").show();
					}else{
						//alert("There is NO short video.");
						//$(".global-video:eq("+parentIndex+")").find(".playVideo").show();
					}
					

				};
			});
	  //       $("body").one('click', '.framed .actions svg',function(e){ 
			// 	console.log("CLICK ON SVG");
			// });
	     
	        
	        //  END Youtube API Ready 
	        //  -------------------------------------------------
 

		}; // if istablet or desktop
	
	//  END YOUTUBE API 
	//  -------------------------------------------------